import { IGender, ICardType, IUserStatus } from "./Iuser";

export interface ICustomerAddress {
  // Address
  line1?: string;
  agentId?: string;
  line2?: string;
  city?: string;
  country?: string;
  state?: string;
  postal_code?: string;
}

export interface ICustomer {
  id?: string;
  email?: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  secondaryPhone?: string;
  whatsappPhone?: string;
  gender?: IGender;
  birthDay?: string;
  language?: string;
  jobTitle?: string;
  status?: IUserStatus;
  lastActive?: number;

  // Chrome Extension
  aliExpressUserId?: string;
  sheinUserId?: string;

  // Social Media
  facebookUrl?: string;
  instagramUrl?: string;
  twitterUrl?: string;
  linkedinUrl?: string;
  youtubeUrl?: string;
  tiktokUrl?: string;

  // Order
  lastOrderDate?: string;
  lastOrderId?: string;
  lastOrderAmount?: number;
  orderCount?: number;
  totalSpent?: number;

  // Address
  line1?: string;
  agentId?: string;
  line2?: string;
  city?: string;
  country?: string;
  state?: string;
  postal_code?: string;

  shippingAddress?: ICustomerAddress;
  billingAddress?: ICustomerAddress;

  notes?: string;
  livitPayCardId?: string;
  livitPayCardType?: ICardType;

  // TODO: Migrate cardId to DivvyCardHolderId

  cardId?: string;
  divvyCardHolderId?: string;
  userID?: string;
  businessName?: string;

  shopId?: string;

  // Created and Updated
  createdAt?: string;
  updatedAt?: string;
}

export enum ICustomerIndexName {
  BY_USER = "byUser",
  BY_SHOP_ID = "byShopId",
  BY_EMAIL = "byEmail",
}

export interface ICheckoutCustomer {
  name: string;
  email: string;
  phone: string;
}
