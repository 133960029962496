import { IPaymentMethod } from "./Iorderhistory";

export interface IPGPayTokenData {
  orderId?: string;
}

export type IMerchantData = {
  name?: string;
};

export enum PGPayEventName {
  CHECK_BALANCE = "check-balance-flow",
  VISA_RELOAD = "visa-reload-flow",
  MONCASH_DEPOSIT = "moncash-deposit-flow",
  MONCASH_WITHDRAW = "moncash-withdraw-flow",
  SEND_TRANSFER = "send-transfer-flow",
  RELOAD_BINANCE = "reload-binance-flow",
  BUY_GIFT_CARD = "buy-gift-card-flow",
  TOP_UP = "top-up-flow",
}

export type PGPayMedataData = {
  customerEmail?: string;
  giftCardId?: string;
  redirectUrl?: string;
  isProd?: string;
  paymentMethods?: IPaymentMethod[];
  pgPayMerchantName?: string;
  customerFirstName?: string;
  shopId?: string;
  customerLastName?: string;
  returnUrl?: string;
  isChatEnabled?: boolean;
};

export type PGPayCryptoMetadata = {
  customerEmail?: string;
  giftCardId?: string;
  redirectUrl?: string;
  isProd?: string;
  pgPayMerchantName?: string;
  customerFirstName?: string;
  customerLastName?: string;
  returnUrl?: string;
  amount?: number;
  paymentMethod?: string;
  userID?: string;
  webhookUrl?: string;
  fees?: number;
  orderId?: string;
};
